
import * as Components from "./Greatgrill"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "greatgrill"
}
